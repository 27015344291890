/* CookieConsent.css */
.cookie-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* width: 50%; */
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .cookie-popup {
    background-color: #fff;
    color: #000;
    padding: 20px;
    width: 30% !important;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .cookie-text {
    margin: 0 0 15px 0;
  }
  
  .cookie-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .cookie-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cookie-button:hover {
    background-color: #0056b3;
  }
  @media (max-width: 767px) {
    .cookie-popup {
      background-color: #fff;
      color: #000;
      padding: 20px;
      width: 100% !important;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      text-align: center;
    }
  }